.Property {
  height: 26rem;

  .ContentContainer {
    transform: translateY(calc(5rem));
  }

  .ThumbnailContainer {
    margin-bottom: -5rem;
  }

  &:hover {
    .ContentContainer {
      transform: translateY(0);
    }

    .ThumbnailContainer {
      margin-bottom: 0;

      img {
        transform: scale(1.25);
      }
    }
  }
}
