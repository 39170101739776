@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  color: #383b54;
  background-color: #f9f9fc;
  font-weight: 400;
  line-height: 1.6;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

pre,
body {
  font-family: "Montserrat", "Open Sans", sans-serif;
}

input,
select,
textarea,
button {
  font-size: 1em;
  color: #383b54;
}

button:focus {
  outline: none;
}

// disabling styles for autofill, can only make long transition to make transparent
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 50000s;
  -webkit-text-fill-color: inherit;
}

.rc-collapse-header {
  background: #fff !important;
  border-bottom: 1px solid #dae1f3;
}

.rc-collapse * {
  border-color: #dae1f3 !important;
}

.rc-collapse-content {
  padding: 16px !important;
  overflow: inherit !important;
}

.card {
  @apply bg-white p-10 relative rounded-2xl border border-border-gray transition duration-300;

  min-height: 300px;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 22px 43px rgba(50, 58, 120, 0.18);
  }

}


*::-webkit-scrollbar {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 1rem;
  background-color: #eaf0f7;
}

*::-webkit-scrollbar-thumb {
  background-color: #dae1f3;
  border-radius: 1rem;
}

*::-webkit-scrollbar-corner,
*::-webkit-resizer {
  display: none;
}

//TODO: move up & fix shadow
.rc-tooltip {
  font-size: 1em !important;
  background-color: rgba( 0, 0, 0 ,0) !important;
  box-shadow: rgba(50,58,120, 0.18) 3px 13px 29px;

}

.rc-tooltip-arrow{
  border-top-color: white !important;
}

.rc-tooltip-inner{
  border: none !important;
  border-radius: 1rem !important;
}

// date time plugin
.rdt {
  flex: 1;
  @apply py-2 px-4  bg-gray-100 border border-gray-200 rounded-lg flex items-center  w-80;

  & > input {
    @apply bg-transparent outline-none  w-full;

    &::placeholder {
      @apply text-gray-300;
    }
  }
}

.inset-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  @apply text-3xl font-semibold mb-6;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leaflet-pane.leaflet-map-pane,
.leaflet-top.leaflet-left,
.leaflet-top.leaflet-bottom,
.leaflet-bottom.leaflet-right {
  z-index: 0;
}

.tox .tox-editor-header {
  z-index: 0;
}

.fade-b:after {
  content  : "";
  position : absolute;
  z-index  : 1;
  bottom   : 0;
  left     : 0;
  pointer-events   : none;
  //TODO: make it more transitional
  background-image : linear-gradient(to bottom, 
                    rgba(249,249,252, 0), 
                    #f9f9fc 90%);
  width    : 100%;
  height   : 10rem;
}

.swiper-button-next{
  right: 20px !important;
}
.swiper-button-prev{
  left: 20px !important;
}