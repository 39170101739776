.Arc {
  width: 100%;
  margin: auto;
  display: block;
}

.Outline,
.Progress {
  stroke-linecap: round;
  stroke-width: 2;
  fill: none;
}

.Outline {
  stroke: lightgrey;
}

.Progress {
  stroke: purple;
  stroke-dasharray: calc(45 * 3.142 * 1);
}
