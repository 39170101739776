// https://stackoverflow.com/questions/19400183/how-to-style-chat-bubble-in-iphone-classic-style-using-css-only
.LastMessage {
  position: relative;

  //  &:before {
  //       content: "";
  //       position: absolute;
  //       z-index: 0;
  //       bottom: 0;
  //       right: -8px;
  //       height: 20px;
  //       width: 20px;
  //       background: #4332D5;
  //       border-bottom-left-radius: 15px;
  //     }

  //    &:after {
  //       content: "";
  //       position: absolute;
  //       z-index: 1;
  //       bottom: 0;
  //       right: -10px;
  //       width: 10px;
  //       height: 21px;
  //       background: #F6F9FC;
  //       border-bottom-left-radius: 10px;
  //     }
}
