.Line {
  height: 7px;
  width: 100%;
  background-color: lightgray;
  border-radius: 7px;
  position: relative;
  margin-bottom: 3rem;

  .Thumb {
    position: absolute;
    left: calc(50% - 3px);
    top: -4px; // (15 - 7) / 2
    height: 15px;
    width: 6px;
    border-radius: 6px;
    background-color: blue;
  }

  .MinValue,
  .MaxValue {
    position: absolute;
    top: calc(100% + 0.5rem);
    font-weight: 700;
  }

  .MinValue {
    left: 0%;
  }
  .MaxValue {
    right: 0%;
  }
}
