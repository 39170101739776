.ModalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(56, 59, 84, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.Modal {
  background-color: white;
  position: relative;
  max-width: 80vw;
  max-height: 80vh;
  display: flex;
  border-radius: 1rem;

  .CloseButton {
    position: absolute;
    cursor: pointer;
    top: 0.5rem;
    line-height: 1;
    right: 1rem;
    font-size: 4rem;
    color: #717186;
  }
}
